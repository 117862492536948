import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import PageHeader from "../components/elements/PageHeader";

const RequestDemo = () => (
  <Index>
    <Seo title="Request Whitepaper" />
    <PageHeader>
      <h1>Request Whitepaper</h1>
      <p className="align-center lead">
        Thank you for your interest in InCircle. An InCircle representative <br />
        will email you a link to download the whitepaper.
      </p>
    </PageHeader>
    <Container>
    </Container>
  </Index>
)

export default RequestDemo
